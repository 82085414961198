.navbar {
    /* background-color: #1f5156; */
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
font: normal normal medium 18px/22px Montserrat;
letter-spacing: 0px;
color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    z-index: 11;
  }
  @media (min-width: 1200px) {
  .nav-container {
width: 1200px;
  }
  }
  .main-container{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    /* background-color:rgba(0,0,0,0.3); */
  }

  .nav-logo {
    /* color: #f5b921; */
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  }

  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }

  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }

  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
#menu{
  background: #305AB9 0% 0% no-repeat padding-box;
}
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  .nav-item:hover:after {
    width: 100%;
    background: #fff;
  }

  .nav-item .active {
    color: #fff;
    border-bottom: 1px solid #ffff;
  }

  .nav-icon {
    display: none;
  }

  @media screen and (max-width: 960px) {

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      text-align: left;
    }

    .nav-menu.active {
      background: #fff;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 100vh;
    }
    .nav-item .active {
      color: #ffdd40;
      border: none;
    }
    .nav-item  {
      padding-left: 50px;
    }
    .nav-links {
      padding: 0.1rem;
      width: 100%;
      display: table;
      color: #000;
    }

    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #ffdd40;
    }
  }