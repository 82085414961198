.sol-secound {
  margin-top: -150px;
  position: relative;
  z-index: 1;
}

.solbck {
  background: rgb(215, 230, 247);
  background: linear-gradient(180deg, rgba(215, 230, 247, 1) 0%, rgba(255, 255, 255, 1) 90%);
}

.sol-secound .sol-third-head h2 {
  text-align: center;
  font: normal normal bold 59px/62px Montserrat;
  letter-spacing: 0px;
  color: #262626;
  padding-top: 20%;
}


.sol-secound .sol-third-head p {
  text-align: center;
  font: normal normal 600 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  width: 67%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.solsec1 {
  display: flex;
}

.solsec1 sup {
  font-size: 60%;
}

.solsec1 h4 {
  text-align: left;
  font: normal normal bold 59px/62px Montserrat;
  letter-spacing: 0px;
  color: #262626;
  margin: 0px;
}

.solsec1 p {
  font: normal normal 500 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #484848;
}

.solsec1 b {
  font: normal normal 600 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

@media screen and (min-width: 768px) {
  .solsec1 {
    margin: 200px 0px;
  }

  .solsec1 p {

    padding-bottom: 75px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .sol-secound .sol-third-head h2 {}
}

@media screen and (max-width: 767px) {

  .sol-secound .sol-third-head h2,
  .solsec1 h4,
  .solsec1 b {
    font-size: 24px;
    text-align: left;
    padding-left: 40px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .sol-secound .sol-third-head p,
  .solsec1 p {
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-left: 40px;
    line-height: 20px;
    padding-right: 10px;
  }

  .sol-third-head p,
  .solsec1 p,
  .solsec1 h4,
  .solsec1 b {
    padding-left: 0;
  }

  .solsec1 {
    margin: 0px;
    margin-bottom: 0;
  }

  .col-5 {
    padding-top: 30px;
  }

  .col-7 {
    padding-top: 20px;
  }

  .solsec1 b {
    padding-top: 30px;
    display: block;
  }

}