.secound .sright h2 span {
    text-align: left;
    font: normal normal 600 48px/58px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
    display: block;
    padding-left: 79px;
}

.secound .sright h2 b {
    text-align: left;
    font: normal normal bold 66px/81px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
}

.secound .sright ul {
    list-style: none;
    padding: 0px;
}

.secound .sright ul li p {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 1px;
}

.secound .sright ul li p span:nth-child(2) {
    padding-left: 10px;

}

.secound .sright ul li {
    line-height: 24px;
}

.sright .button,
.btnbtom-logo {
    text-align: center;
}

div.container>div.home-secound>div.button {
    display: none;
}

div.container>div.home-secound>p.mobile {
    display: none;
}

/* Larger than mobile */
@media (min-width: 320px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 480px) {}


@media (min-width: 320px) and (max-width: 767px) {
    .secound .sright h2 span {
        font: normal normal 600 22px/8px Montserrat;
    }

    .secound .sright h2 b {
        font: normal normal bold 26px/48px Montserrat;
    }

    .secound .sright {
        padding: 2px;
    }

    .secound .sleft img {
        width: 100%;
        height: 100%;
        max-width: 309px;
        max-height: 374px;
    }

    .secound .sleft {
        width: 45%;
        text-align: right;
    }

    .secound .sright {
        width: 48%;
        text-align: left;
    }

    .sright .button,
    .btnbtom-logo {
        display: none;
    }

    div.container>div.home-secound>div.button {
        display: block;
        margin-left: 60px;
    }

    div.container>div.home-secound>p.mobile {
        display: block;
    }

    .secound .sright ul li p span img {
        width: 20px;
    }

    .secound .sright ul li p span {
        font-size: 14px;
    }
}

/* Larger than tablet */
@media (min-width: 767px) {
    .secound .sleft img {
        width: 100%;
        height: 100%;
        max-width: 309px;
        max-height: 594px;
    }

    .secound .sleft {
        width: 50%;
        text-align: right;
    }

    .secound .sright {
        width: 35%;
        text-align: left;
    }
}

@media (min-width: 767px) and (max-width: 992px) {

    .secound .sright h2 b {
        font: normal normal bold 56px/71px Montserrat;
    }
}


/* Larger than desktop */
@media (min-width: 1024px) {

    .secound .sright {
        padding-left: 50px;
    }

    .secound .sright ul {
        padding-left: 18px;
        padding-bottom: 58px;
    }

    .secound .sright p {
        margin-bottom: 34px;
    }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}