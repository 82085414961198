body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.container {
  height: auto;
  margin: 0 auto;
  position: relative;
}

ul {
  list-style: none;
  padding: 0px;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: var(--half-gutter-width, 0.5rem);
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}



.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 94.333333%;
}

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}



.start {
  justify-content: flex-start;
  text-align: start;
}

.center {
  justify-content: center;
  text-align: center;
}

.end {
  justify-content: flex-end;
  text-align: end;
}

.top {
  align-items: flex-start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}

.clearfix {
  *display: inline-block;
  _height: 1%;
}

/* Larger than mobile */

@media (min-width: 320px) {
  .container {
    width: 100%;
    overflow: hidden;
  }
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 480px) {}

/* Larger than tablet */
@media (max-width: 767px) {
  .bannersectionhome .one .btn ul span img {
    max-width: 100%;
  }

  .bannersectionhome .one .btn ul span {
    max-width: 33.33%;
    display: block;
  }

  .bannersectionhome .one .btn ul li {
    display: flex;
  }

  .bannersectionhome .one .btn ul {
    margin-top: 35px;
  }

  .home-secound {
    padding-top: 90px;
  }

  .bannersectionhome {
    padding-top: 10px !important;
  }
}

/* Larger than tablet */
@media (max-width: 1024px) {

  .col-6,
  .col-7,
  .col-5 {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 10%;
  }
}

/* Larger than tablet */
@media (min-width: 768px) {}

/* Larger than desktop */
@media (min-width: 1025px) {
  .col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding: 0px;

  }

}