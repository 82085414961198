.trusted {
    position: relative;
    text-align: center;
}

.trusted h3 {
    font: normal normal bold 77px/94px Montserrat;
    letter-spacing: 0px;
    color: #262626;
}

.trusted h3 span {
    font: normal normal 600 45px/55px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    display: block;
}

.carosouluserimg img {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
    .trusted h3 {
        font: normal normal bold 30px/44px Montserrat;
    }

    .trusted h3 span {
        font: normal normal 600 20px/25px Montserrat;
    }
}

@media (min-width: 767px) {
    .trusted {
        margin-bottom: 150px;
    }

    .trusted h3 {
        font: normal normal bold 77px/94px Montserrat;
    }

    .trusted h3 span {
        font: normal normal 600 45px/55px Montserrat;
    }
}