.contact .topimg{
    top:0px;
    height: 309px
}
.contact .topimg img{
height: 100%;

}

.contact .topsection .bannersection .topimage{
    margin: 0 auto;
}
.contact .topsection .bannersection .one {
    display: flex;
    justify-content: left;
}
.contact .topsection .bannersection .one h1 {
    text-align: left;
    font: normal normal 600 43px/49px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 50%;
}

.cont-secound{
    margin: 150px 0px;
}
@media screen and (max-width:1024px){
    .contact .cont-secound {
        margin: 50px 0px;
    }
}