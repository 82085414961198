.topsectionhome {
    /* background: url("../../staticassets/homepage/first/background/waves1@2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover; */
    position: relative;
    z-index: 1;
    background: #305AB9 0% 0% no-repeat padding-box;
}

.w1 {
    fill: #0E7452;
    opacity: 0.4;
}

.w2 {
    fill: #0E7452;
    opacity: 0.7;
}

.w3 {
    fill: #0B5B41;
}

.svgflip {
    /* transform: scaleY(-1); */
    position: absolute;
    width: 100%;
    /* margin-top: -5px; */
}

/* #Trazado_2082{
   z-index: 3;
}
#Trazado_2083{
   z-index: 2;
}
#Trazado_2084{
   z-index: 1;
} */
/* svg{
    -webkit-filter: invert(100%);
    filter: invert(100%);
} */

.topimg {
    position: absolute;
    width: 100%;
    z-index: 0;
}

.topimg img {
    width: 100%;
}



.bannersectionhome {
    padding-top: 78px;
    position: relative;
    z-index: 1;
    padding-bottom: 42px;
}

.bannersectionhome .one h1 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.bannersectionhome .one p {
    letter-spacing: 0px;
    color: #FFFFFF;
}

.bannersectionhome .one .btn ul {
    padding: 0px;
    list-style: none;
    width: 100%;
}

.bannersectionhome .one .btn ul li span {
    padding-right: 20px;
}

.Home .bannersectionhome .topimage img {
    width: 100%;
    height: 100%;
    max-width: 623px;
    max-height: 564px;
}

.bannersectionhome .topimage {
    position: relative;
}

@media (min-width: 1025px) {

    .Home .topimg img {
        height: 912px;
    }

    .bannersectionhome .one h1 {
        font: normal normal 600 43px/53px Montserrat;
    }

    .bannersectionhome .one p {
        font: normal normal 400 22px/27px Montserrat;
        width: 80%;
        text-align: left;
    }

    .bannersectionhome .topimage {
        margin-left: -18%;
        margin-top: -5%;

    }

    .bannersectionhome .one .btn .button {
        margin-top: 59px;
        margin-left: 50px;
    }
}

@media (min-width: 480px) and (max-width: 1024px) {
    .bannersectionhome {
        padding: 0px 100px;
        text-align: center;
    }

    .bannersectionhome .one h1 {
        font: normal normal 600 43px/53px Montserrat;
        text-align: center;
    }

    .Home .topimg img {
        height: 980px;
    }

    .bannersectionhome .one p {
        font: normal normal 400 22px/27px Montserrat;
        text-align: center;
    }

    .bannersectionhome .two .topimg {
        padding: 0px 130px;
    }

    .bannersectionhome .one .btn .button {
        margin-top: 50px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .button button {
        width: 185px;
        height: 45px;
        font: normal normal bold 13px/24px Montserrat;
        margin-left: 45px;
        margin-top: 25px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .Home .topimg img {
        height: 880px;
    }
}


@media (min-width: 320px) and (max-width: 480px) {
    .bannersectionhome .one h1 {
        font: normal normal 600 27px/33px Montserrat;
    }

    .bannersectionhome .one p {
        font: normal normal 400 15px/20px Montserrat;
    }

    .bannersectionhome .one .btn .button {
        margin-top: 15px;
        margin-left: 15px;
    }
}

@media (min-width: 481px) and (max-width: 1024px) {}

/* Larger than tablet */
@media (min-width: 767px) {
    .topsection {
        width: 100%;
        height: 100%;
    }

}

/* Larger than desktop */
@media (min-width: 1024px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}