.abt-third .third-left {
    margin-top: 150px;
    padding-bottom: 60px;
}

.abt-third .third-left h2 {
    font: normal normal bold 43px/53px Montserrat;
    letter-spacing: 0px;
    color: #2B2A2A;
    text-transform: capitalize;

}

.abt-third-back {
    background: rgb(210, 230, 254);
    background: linear-gradient(180deg, rgba(210, 230, 254, 1) 0%, rgba(255, 255, 255, 1) 90%);
    margin-top: -5px;
}

.left-location {
    display: flex;
    align-items: center;
    padding-bottom: 60px;
}

.left-location p {
    font: normal normal 400 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
    padding-left: 40px;
}

.abt-third {
    margin-bottom: 60px;
}

@media screen and (max-width: 767px) {

    .abt-secound .abt-third-head h2,
    .abt-third .third-left h2 {
        font-size: 26px;
        margin-top: 45px;
        padding-left: 40px;
        padding-top: 10px;
        text-align: left;
        margin-bottom: 0;
    }

    .teamphots ul li {
        width: calc(50% - 60px);
        flex: 0 0 calc(50% - 60px);
        margin: 10px;
        margin-right: 50px;
        margin-bottom: 80px;
    }

    .teamphots ul li .inner p {
        font-size: 12px;
        left: 0;
        padding: 0 8px;
        bottom: 0;
    }

    .teamphots ul {
        padding: 0 20px;
    }

    .teamphots ul li .inner {
        height: calc(100% + 40px);
        bottom: -64px;
        right: -20px;
    }

    .abt-third .third-left {
        margin-top: 50px;
    }

    .abt-third .third-left img {
        max-width: 50px;
        width: 50px;
        height: auto !important;
    }

    .abt-third-back {
        padding: 0 30px;
    }

    .abt-third .third-left h2 {
        padding-left: 0;
        margin-top: 0;
    }

    .left-location {
        align-items: flex-start;
    }

    .left-location p {
        padding-left: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }
}

@media screen and (max-width: 560px) {

    .left-location {

        flex-direction: column;
    }
}

@media screen and (max-width: 420px) {
    .teamphots ul li .inner p {
        font-size: 11px;
    }

    .teamphots ul li {
        margin-left: 0;
        width: calc(50% - 40px);
        flex-basis: calc(50% - 40px);
        margin-right: 40px;
    }
}