.thank-secound{
    margin-top: 65px;
}
.txt-th{
    text-align: left;
font: normal normal 400 29px/44px Montserrat;
letter-spacing: 0px;
color: #000000;
margin-bottom: 70px;
}

@media screen and (max-width: 1024px) {
    .txt-th{
text-align: center;
    }
    .thank-secound {
        text-align: center;
    }
}