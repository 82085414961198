.topimg {
    top: 0px;
}

.publisher {
    min-height: 544px;
}

.publisher .topsection .bannersection .topimage {

    margin-top: 10%;
}

.publisher .topsection .bannersection .one h1 {
    font: normal normal 600 43px/57px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0px;
}

.publisher .topsection .bannersection .one h1 b {
    font: normal normal bold 63px/57px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;

}

.publisher .bannersection .one .btn .button {
    margin-top: 40px;
}

.pub-tren {
    /* `margin-top: 200px; */
    margin-bottom: 100px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .publisher .topsection .bannersection .one h1 {
        font: normal normal 600 25px/40px Montserrat;
        text-align: center;
    }

    .publisher .topsection .bannersection .one h1 b {
        font: normal normal bold 45px/57px Montserrat
    }

    .publisher .bannersection .one p {
        font: normal normal 500 18px/27px Montserrat
    }

    .publisher .bannersection .one .btn .button {
        margin-left: 7%;
    }

    .publisher .bannersection .topimage img {
        margin-left: -8%;
    }
}

@media (max-width: 1024px) {
    .topimg.pub {
        width: 100%;
        height: 1024px;
    }

    .topimg.pub img {
        width: 100%;
        height: 100%;
    }

}

@media (max-width: 767px) {
    .pub-secound {
        margin: 20px;
    }

    .pub-third .box {
        margin: 20px;
    }

    .pub-fourth .circle {
        margin: 20px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .pub-secound {
        margin: 100px;
    }

    .pub-third .box {
        margin: 100px;
    }

    .pub-fourth .circle {
        margin: 100px;
    }
}


@media (min-width: 1025px) {
    .topimg.pub {
        width: 100%;
        height: 909px;
    }

    .topimg.pub img {
        width: 100%;
        height: 100%;
    }
}