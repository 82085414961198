form {
    padding: 10px;
    padding-left: 10%;
}

.input {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.input label {
    flex: 0 0 20%;
}

.input input,
.input textarea {
    height: 72px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #D0CECE;
    border-radius: 11px;
    flex: 0 0 50%;
    padding: 10px;
}

.input input[name="companyName"],
.input input[name="name"],
.input input[name="AboutIntentX"],
.input input[name="tellUs"],
.input textarea {
    text-transform: capitalize;

}

.input li {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.input li input {
    width: 20px;
    height: 20px;
    border-radius: 5px;

}

.cbtn {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-left: 50px;
}

button.form-button {
    background: #F48D10 0% 0% no-repeat padding-box;
    border-radius: 37px;
    width: 259px;
    height: 66px;
    text-align: center;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 0px;
}

.intersted input {
    flex: 0 0 20%;
    margin-right: 10px;
}

.intersted li:last-child span sup {
    display: none;
}

@media (max-width:560px) {
    .input {
        flex-direction: column;
    }

    .input input,
    .input textarea,
    .input label {
        flex: 0 0 100%;
        width: 100%;
    }

    form {
        padding: 10px;
        margin: 20px;
    }
}

@media screen and (max-width: 767px) {

    input,
    textarea {
        box-sizing: border-box;
    }

    textarea {
        min-height: 100px;
    }

    label {
        padding-bottom: 8px;
    }

    .input li {
        justify-content: flex-start;
    }

    .input li input {
        flex: auto;
    }

    .input li span {
        width: 100%;
    }

    .intersted {
        padding-top: 20px;
        width: 100%;
    }
}