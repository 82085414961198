


.abt-mission1{
margin-top: 100px;
width: 50%;
margin-left: 30px;
}


.abt-mission1 p {
    text-align: left;
    font: normal normal 400 29px/44px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}