.pub-fifth{
    margin-top: -60px;
    /* display: flex; */
    align-items: center;
}
.pub-fifth-bck{
    background: #305AB9;
    padding: 120px 0px;

}

.pub-fifth-carousal{
    /* width: 90%; */
    width: 100%;
    margin: 0 auto;
}

.pub-fifth-carousal .carousal1sect ul li p,
.pub-fifth-carousal .carousal1sect ul li span{
color: #fff;
}
.image-item-pub{
    padding-left: 10px;
    width: 525px!important;
    height: 453px;
}


.carosoulbox{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 13px 26px #1747899B;
border-radius: 24px;
padding: 10px;
text-align: center;
height: 430px;
}
.carosoulbox .carosoulhead{
    padding-top: 25px;
}
.carosoulbox .carosoultext p{
    font: normal normal 600 23px/30px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}
.carosoulbox .carosouluserinfo {
    /* display: flex; */
    padding-top: 30px;
}

/* .carosoulbox .carosouluserinfo .carosouluserimg{
width: 140px;
} */
.carosoulbox .carosouluserinfo .carosouluserimg img{
border-radius: 24px;
}
.carosoulbox .carosouluserinfo .carosoulusername{
    text-align: center;
}
.carosoulbox .carosouluserinfo .carosoulusername p{
    margin-bottom: 1px;
    font: normal normal 600 17px/20px Montserrat;
letter-spacing: 0px;
color: #000000;
text-transform: capitalize;
}
.carosoulbox .carosouluserinfo .carosoulusername span{
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}