.discover {
    display: flex;
    /* margin-top: 100px; */
}

.dis-txt {
    width: 50%;
    position: relative;
    padding-top: 16%;
}

.dis-img {
    width: 50%;
    position: relative;
    z-index: 2;
    margin-top: -60px;
}



@media (min-width: 993px) {
    .xtpor {
        background: rgba(255, 255, 255, 0.12);
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        box-shadow: 0px 13px 36px #17478976;
        border-radius: 24px;
        /* opacity: 0.12; */
        margin-left: 17%;
        position: absolute;
        right: -43%;
        width: 100%;
        padding: 50px 30px;
    }

}


.xtpor b {

    font: normal normal 600 17px/27px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.xtpor p {
    width: 81%;
    font: normal normal normal 16px/22px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.carousal1bdisc .react-multi-carousel-dot-list li {
    padding-left: 30px;
}

@media (min-width: 320px) and (max-width: 992px) {
    .cahead {
        padding-left: 0.1%;
    }

    .cahead h3 {
        text-align: center;
        font: normal normal bold 20px/44px Montserrat
    }

    .cahead h3 b {
        text-align: center;
        font: normal normal bold 50px/44px Montserrat;
        padding-left: 0%;
    }

    .discover {
        padding: 10px;
        background: rgba(255, 255, 255, 0.12);
        box-shadow: 0px 13px 36px #17478976;
        border-radius: 24px;
        margin: 40px;
    }

    .dis-txt {
        margin-left: 10%;
    }


    .xtpor b {
        font: normal normal 600 17px/27px Montserrat;
    }

    .xtpor p {
        width: 100%;
        font: normal normal normal 16px/22px Montserrat;
    }

    .carousal1bdisc .react-multi-carousel-list {
        margin-top: -5%;
    }

    .carousal1bdisc .react-multi-carousel-dot-list {
        bottom: 1%;
    }
}