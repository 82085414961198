.carousal1bdisc {
    /* background: url("../../../staticassets/homepage/fifth/background/backgroundwaves3@2x.png") no-repeat center center; */
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover; */
    width: 100%;
    /* height: 150vh; */
    /* display: flex;
     align-items: center; */
    background: #305AB9;
}

/* .image-item1{
 background: url("../../../staticassets/homepage/first/background/waves1@2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
     width: 100vw;
     height: 100vh;
} */


.cahead h3 {
    text-align: left;
    font: normal normal 600 45px/55px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.cahead h3 b {
    font: normal normal bold 66px/69px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    display: block;
    padding-left: 10%;
}

.cahead {
    padding-top: 6%;
    padding-left: 23%;
}

.carousal1bdisc .react-multi-carousel-list {
    margin-top: -15%;
}

.carousal1bdisc .react-multi-carousel-dot-list {
    margin-top: 48px;
    bottom: 21%;
}

@media (min-width: 320px) and (max-width: 767px) {
    .carousal1bdisc {
        height: 667px;
    }

}