.contact-advisor .topimg{
    top:0px;
    height: 309px
}
.contact-advisor .topimg img{
height: 100%;

}

.contact-advisor{
    min-height: 1000px;
}


.contact-advisor .topsection .bannersection .topimage{
    margin: 0 auto;
}
.contact-advisor .topsection .bannersection .one {
    display: flex;
    justify-content: left;
}
.contact-advisor .topsection .bannersection .one h1 {
    text-align: left;
    font: normal normal 600 43px/49px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 80%;
}
.contadv-secound{
    margin: 100px 0px;
}

@media (max-width: 767px){

    .contact-advisor .topsection .bannersection .one h1 {
    font: normal normal 600 23px/29px Montserrat;

    }
    .contadv-secound .abt-integ{
        flex-direction: column;
    }
    .contadv-secound h2 {
        font: normal normal 600 30px/37px Montserrat;
    }

}