.third {
    position: relative;
}

.massivetop-ywaves {
    /* position: relative;
     background: url("../../../../src/staticassets/homepage/third/background/yellowwaves2@2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover; */
    /* position: absolute;
    width: 100%;
    z-index: 0; */
}

.massivetop-ywaves {
    position: relative;
    z-index: 1;
}

.adv-mass-bck {
    background: rgb(240, 245, 250);
    background: linear-gradient(180deg, rgba(240, 245, 250, 1) 0%, rgba(255, 255, 255, 1) 90%);

}

.massivetop-ywaves1 {
    position: absolute;
    width: 100%;
    height: 500px;
    overflow: hidden;

}

.massivetop-ywaves img {
    width: 100%;
}

.massivetop-scale h3 {
    text-align: center;
    font: normal normal 600 30px/37px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

.massivetop-scale {
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 20vh 0px;

}

.massivetop-scale .first,
.massivetop-scale .secound,
.massivetop-scale .third {
    flex: 33.33%;
}

.massivetop-scale .first p,
.massivetop-scale .secound p,
.massivetop-scale .third p {
    font: normal normal bold 73px/77px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    margin: 0px;
}

.massivetop-scale .first span,
.massivetop-scale .secound span,
.massivetop-scale .third span {
    text-align: center;
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
}

@media (min-width: 1025px) {
    .adv-mass .massive-scale {
        padding: 0px 150px 150px;

    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .massivetop-scale h3 {
        font: normal normal 600 23px/37px Montserrat;
    }

    .massivetop-scale {
        padding: 3vh 0px;
    }
}

@media (max-width: 768px) {

    .massivetop-scale .first span,
    .massivetop-scale .secound span,
    .massivetop-scale .third span {
        font: normal normal 600 14px/24px Montserrat;
    }

    .massivetop-scale .first p,
    .massivetop-scale .secound p,
    .massivetop-scale .third p {
        font: normal normal bold 43px/57px Montserrat;
    }
}

@media screen and (max-width: 767px) {
    .adv-mass-bck {
        margin-bottom: 60px;
    }
}