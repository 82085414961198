.adv-secound .adv-head {
   text-align: center;
}

.adv-secound {
   margin-bottom: 300px;
}

.advrow1 {
   top: -206px;
   width: 66%;
   position: absolute;
   text-align: center;
   margin: 0 auto;
   left: 19%;
}

.adv-secound .adv-head h2 {
   text-align: center;
   font: normal normal bold 48px/58px Montserrat;
   letter-spacing: 0px;
   color: #262626;
   text-transform: capitalize;
}

.adv-secound .adv-head p {
   width: 66%;
   margin: 0 auto;
   text-align: center;
   font: normal normal 400 26px/32px Montserrat;
   letter-spacing: 0px;
   color: #484848;
}


/* .adv-secound .adv-im1 .advone {
   padding-top: 30px;

} */
.adv-secound .adv-im1 .advrow {
   justify-content: center;
   align-items: center;
   margin-bottom: 60px;
}

.adv-secound .adv-im1 .advone h3 {
   font: normal normal 600 30px/37px Montserrat;
   letter-spacing: 0px;
   color: #000000;
   /* padding-top: 70px; */
}

.adv-secound .adv-im1 .advone p {
   font: normal normal 400 26px/32px Montserrat;
   letter-spacing: 0px;
   color: #484848;
   width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {

   .adv-secound .adv-head h2 {
      font: normal normal bold 25px/35px Montserrat;

   }

   .adv-secound .adv-head p {
      font: normal normal 15px/18px Montserrat
   }

   .adv-secound .adv-im1 .advone h3 {
      font: normal normal 600 20px/37px Montserrat;
   }

   .adv-secound .adv-im1 .advone p {
      font: normal normal 400 18px/25px Montserrat;
   }
}

@media screen and (min-width:768px) {
   .adv-secound .adv-head {
      margin-top: 200px;
   }


   .adv-secound .adv-im1 .advrow {

      margin-bottom: 122px;

   }

   .adv-im1 {
      margin-top: 72px;
   }
}

@media screen and (max-width:767px) {
   .adv-secound .adv-head {
      /* margin-top: 100px; */
   }

   .adv-im1 {
      margin-top: 30px;
   }
}