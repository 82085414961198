.contadv-secound{
    padding: 10px;
}
.contadv-secound h2{
    font: normal normal 600 39px/44px Montserrat;
letter-spacing: 0px;
color: #000000;
text-transform: capitalize;
margin-bottom: 0px;
}
.contadv-secound form{
padding-left: 0%;
}
.contadv-secound .abt-integ{
    display: flex;

}

.contadv-secound .abt-integ p{
    display: flex;
align-items: center;
font: italic normal 500 28px/34px Montserrat;
letter-spacing: 0px;
color: #000000;
margin-top: 0px;
margin-right: 51px;
}
.contadv-secound .abt-integ p span img{
    padding-top: 10px;
    padding-right: 10px;
}
.contadv-formheading{
    font: normal normal 400 25px/30px Montserrat;
letter-spacing: 0px;
color: #2B2A2A;
text-transform: capitalize;
}