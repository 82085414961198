.topimg {
    top: 0px;
}

.about {
    min-height: 1000px;
}

.abt {
    height: 464px;
}

.abt img {
    height: 100%;
}

.about .topimg img {
    height: 100%;
}

.about .topsectiona {
    margin-bottom: 300px;
}

.col-12 {
    width: 100%;
}

.about .topsectiona .bannersection .topimage {
    margin: 0 auto;
}

.about .topsectiona .bannersection .one {
    display: flex;
    justify-content: right;
}

.about .topsectiona .bannersection .one h1 {
    text-align: right;
    font: normal normal 600 43px/49px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 55%;
    margin-left: auto;
}

@media screen and (max-width: 1024px) {
    .about .topsectiona .bannersection .one h1 {
        width: 86%;
    }
}