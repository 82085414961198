.four-main-list{
  list-style-position: outside;
  padding-left: 40px;
}
.four-main-list li{
  list-style-type: lower-alpha;
  padding-left: 10px;
}
.four-main-list li::marker{
  font-weight: normal !important;
}
.twelve-c-list li{
  list-style-type: lower-roman;
  padding-left: 10px;
}
.twelve-c-list{
padding-left: 40px;
list-style-position: outside;
}
.privacy-table{
  margin-top: 20px;
}
.privacy-table th, .privacy-table td{
  padding:15px
}
.privacy-table th{
  background-color: lightgrey;
}