.topimg {
    top: 0px;
}

.solution {
    min-height: 1000px;
}

.solution .topsection1 .bannersection .topimage {
    margin: 0 auto;
}

.solution .topsection1 .bannersection .topimage img {
    height: 100%;
    /* max-height: 525px; */
    width: 100%;
}

.solution .topsection1 .bannersection .one h1 {
    font: normal normal 600 46px/57px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0px;
    text-align: center;
}

.solution .topsection1 .bannersection .one h1 b {
    font: normal normal bold 65px/79px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    display: block;

}

.solution .bannersection .one p {
    font: normal normal bold 20px/43px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-top: 60px;
    width: 100%;
    text-align: center;

}

.solution .bannersection .one {
    width: 100%;
    background: #305AB9;
    padding-top: 100px;
    padding-bottom: 50px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .solution .bannersection .one p {
        font: normal normal bold 20px/43px Montserrat;
    }

    .soltwo .toptxt {
        width: 55%;
        font-size: 17px;
        margin-top: 35%;
        margin-left: 22%;
    }
}

@media screen and (max-width: 767px) {
    .solution .topsection1 .bannersection .one h1 {
        font: normal normal 600 25px/37px Montserrat;
    }

    .solution .topsection1 .bannersection .one h1 b {
        font: normal normal bold 35px/39px Montserrat;
    }

    .solsec1 {
        flex-direction: column;
    }

    .col-7 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-5 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 1025px) {
    .solu {
        height: 600px;
    }

    .solu img {
        height: 100%;
    }
}