.ab-bck {
    background: #305AB9;
    padding-top: 78px;
}

@media screen and (max-width: 767px) {
    .about .topsectiona .bannersection .one h1 {
        text-align: center;
        width: 100%;
        padding: 0 10px;
        font-size: 22px;
        line-height: normal;
        padding-bottom: 70px;
    }
}