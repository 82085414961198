.underlined{
  text-decoration: underline;
}
.terms-body a {
  text-decoration: none;
}
.main-list{
  list-style-position: inside;
}
.main-list h3{
  padding-left: 20px;
  display: inline-block;
}
.main-list li::marker{
  font-weight: bold;
}
.ten-main-list{
  list-style-position:inside ;
}
.ten-main-list li{
  padding-left: 20px;
}
.ten-main-list li::marker, .eleven-list li::marker, .seventen-list li::marker, .twenty-three-list li::marker{
  font-weight: normal;
}