.advi-contact .input {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.advi-contact .input input,
.advi-contact .input select,
.advi-contact .input textarea {
    height: 72px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #D0CECE;
    border-radius: 11px;
    flex: 0 0 90%;
    padding: 10px;
    width: 100%;
    font: small-caption
}

.advi-contact .input li {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.advi-contact .input li input {
    width: 20px;
    height: 20px;
    border-radius: 5px;

}

.input input[name="fname"],
.input input[name="lname"],
.input input[name="companyName"],
.input input[name="affiliteNetwork"],
.input input[name="tellUs"],
.input textarea {
    text-transform: capitalize;

}

.advi-contact button.form-button {
    background: #F48D10 0% 0% no-repeat padding-box;
    border-radius: 37px;
    width: 195px;
    height: 66px;
    text-align: center;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 0px;
}

.advi-contact {
    display: flex;
}

.advi-contact .advi-contact-form {
    flex: 0 0 50%;
}

.advi-contact .advisor-clents {
    flex: 0 0 50%;
}

.advi-contact .advisor-clents .clents {
    display: flex;
    align-items: center;
    flex-direction: row;
}

@media (min-width: 768px) and (max-width: 992px) {
    .advisor-clents .ad-img img {
        width: 120px;
    }
}

@media (max-width: 767px) {
    .advi-contact {
        flex-direction: column;
    }

}