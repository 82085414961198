/* .image-item1{
 background: url("../../../staticassets/homepage/first/background/waves1@2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
     width: 100vw;
     height: 100vh;
} */
.carousal1sect {
   padding: 150px 0px 50px;
   text-align: center;
}

.carousal1sect .react-multi-carousel-list {
   padding-bottom: 50px;

}

.carosoulbsec ul li p {
   width: 75%;
   margin: 0 auto;
   text-align: center;
   font: normal normal 600 30px/37px Montserrat;
   letter-spacing: 0px;
   color: #262626;
   padding-bottom: 20px;
}

.carosoulbsec ul li span {
   font: normal normal 500 20px/24px Montserrat;
   letter-spacing: 0px;
   color: #4167B0;
}

.carosoulbsec ul li img {
   width: 200px;
   margin-bottom: 36px;
}


@media (min-width: 320px) and (max-width: 767px) {
   .carousal1sect {
      padding: 0%;
   }

   .carosoulbsec ul li p {
      font: normal normal 600 21px/31px Montserrat
   }

   .carosoulbsec ul li span {
      font: normal normal 500 15px/24px Montserrat
   }

}