.abt-secound {
  margin-top: -188px;
  position: relative;
  z-index: 1;
}

.abt-mission {
  width: 80%;
  margin: 0 auto;
}

.abt-mission h2 {
  font: normal normal bold 43px/53px Montserrat;
  letter-spacing: 0px;
  color: #2B2A2A;
  text-transform: capitalize;
}

.abt-mission p {
  font: normal normal 400 23px/28px Montserrat;
  letter-spacing: 0px;
  color: #484848;
}

.abt-secound-bck {
  background: rgb(255, 233, 146);
  background: linear-gradient(180deg, rgba(255, 233, 146, 1) 0%, rgba(255, 255, 255, 1) 75%);
  margin-top: -54px;

}

.abt-secound .abt-third-head h2 {
  text-align: center;
  font: normal normal bold 59px/62px Montserrat;
  letter-spacing: 0px;
  color: #262626;
  padding-top: 10%;
}


.teamphots ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.teamphots ul li {
  flex: 0 0 24%;
  position: relative;
  margin: 30px;
  margin-right: 50px;
  margin-bottom: 80px;

}

.teamphots ul li .outer img {
  box-shadow: 0px 13px 36px #4D535C4B;
  border-radius: 24px;
}

.teamphots ul li .outer {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.teamphots ul li .inner {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -70px;
  right: -50px;
}

.teamphots ul li .outer {
  z-index: 2;
}

.teamphots ul li .inner {
  z-index: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 26px #59595A4D;
  border-radius: 24px;
  /* width: 392px;
  height: 342px; */
}

.teamphots ul li .inner p {
  position: absolute;
  bottom: -20px;
  bottom: -15px;
  left: 20px;
  padding: 10px;
}

.teamphots ul li .inner p b {
  display: block;
}


@media screen and (max-width: 1024px) {
  .abt-mission {
    width: 70%;
    margin: 200px auto;
  }
}

@media screen and (max-width: 767px) {

  .abt-mission {
    margin-top: 50px !important;
  }

  .abt-mission h2 {
    font-size: 26px;
  }

  .abt-mission p {
    font-size: 16px;
    line-height: 26px;
  }
}

@media screen and (max-width: 560px) {
  .teamphots ul li {
    flex: 0 0 50%;

  }

  .about .topsection .bannersection .one h1 {
    font: normal normal 600 28px/30px Montserrat;
  }
}