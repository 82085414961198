.pub-seventh h2 {
    font: normal normal bold 48px/58px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
}

.pub-seventh-bck {
    background: rgb(223, 237, 242);
    background: linear-gradient(180deg, rgba(223, 237, 242, 1) 0%, rgba(255, 255, 255, 1) 90%);
    padding: 25px 0px 100px;
}

.pub-seventh p {
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #484848;
}

.pub-seventh .seventh-left {
    margin-top: 200px;
}

.pub-seventh .seventh-right {
    margin-top: 91px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .pub-seventh .seventh-left {
        margin-top: 0;
    }
}

@media (max-width: 1024px) {

    .pub-seventh .col-4,
    .pub-seventh .col-8 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .pub-seventh .massive-ywaves1 {
        height: 1078px;
    }

    .pub-seventh {
        text-align: center;
    }

    .pub-seventh .massive-ywaves1 img {
        height: 100%;
    }


}