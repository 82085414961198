.topimg {
    top: 0px;
}

.advertisers {
    min-height: 500px;
}

.advertisers .topsection .bannersection .topimage {

    margin-top: 0.5%;
    margin-left: 0%;
    ;
}

.advertisers .bannersection .topimage img {
    height: 100%;
    max-height: 525px;
    width: 100%;
}

.advertisers .topsection .bannersection .one h1 {
    font: normal normal 600 44px/54px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.advertisers .bannersection .one .btn .button {
    margin-top: 40px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .advertisers .topsection .bannersection .one h1 {
        font: normal normal 600 24px/30px Montserrat;
        text-align: center;
        margin-left: -3%;
    }

    .advertisers .topsection .bannersection .button button {
        margin-left: 70px;
        margin-top: 10px;
    }

    .advertisers .topsection .bannersection .topimage {
        margin-top: 15%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 1024px) {
    .adve {
        height: 900px;
    }

    .adve img {
        height: 100%;
    }
}