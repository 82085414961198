.footer {
  /* background: url("../../../staticassets/Bottombar/background/backgroundwave2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
     width: 100%;
        height: 340px; */
  position: relative;
}

.footer .footer-bckimg img {
  width: 100%;
}

.footer .footer-bckimg {
  /* position: absolute; */
  bottom: 0px;
  width: 100%;

}

.footerLinks {
  position: relative;
  bottom: -60px;
}

.footerLinks ul {
  list-style: none;
  padding: 0px;
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 15px;
  justify-content: center;
}

.footerLinks .nav-links {
  color: #000;
}

.copyandsocial {
  position: absolute;
  bottom: 11%;
  width: 100%;
  /* padding: 0px 10px; */
}

.copyandsocial .copyright {
  width: 100%;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

/* .copyandsocial .copyright{
  width: 50%;
float: left;
color: #fff;

} */
/* .copyandsocial .socialmedia{
  width: 50%;
float: left;
} */
.copyandsocial .socialmedia ul {
  list-style: none;
  padding: 0px;
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 15px;
  justify-content: center;
}

.copyandsocial .socialmedia ul li a {
  width: 30px;
  height: 30px;
  margin-right: 30px;
}

.cls-1 {
  position: relative;
  z-index: 1;
}

.cls-2 {
  position: relative;
  z-index: -1;
}

.footer .footer-bckimg {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.footer .footer-bckimg svg {
  height: auto;
}


@media (min-width: 320px) and (max-width: 767px) {
  .footer .footer-bckimg {
    display: block;
  }

  .trusted h3 {
    font: normal normal bold 47px/44px Montserrat;
  }

  .trusted h3 span {
    font: normal normal 600 25px/25px Montserrat;
  }
  .footerLinks {
    bottom: -125px;
  }

  .footerLinks ul  {
    flex-wrap: wrap;
  }
  .footerLinks .nav-links {
    padding: 0.1rem 0.4rem;
    color: #fff;
    font-size: 12.5px;

  }

  /* .footer .footer-bckimg{
    height: 175px;
  } */
  .footer .footer-bckimg img {
    height: 100%;
  }

  .copyandsocial .copyright,
  .copyandsocial .socialmedia {
    float: none;
    width: 100%;
    text-align: center;
  }

  .copyandsocial .socialmedia ul {
    margin-bottom: 0px;
  }

  .footer-bckimg svg {
    top: -45px !important;
  }

  .copyandsocial {
    background: #305ab9;
    bottom: 1px !important;
    padding-bottom: 15px;
  }
}