.pub-secound {
   /* margin-top: 150px; */
}

.pub-secound .pub-secound-head {
   padding-left: 84px;
}

.pub-secound .pub-secound-head h2 {
   font: normal normal bold 40px/49px Montserrat;
   letter-spacing: 0px;
   color: #262626;
   text-transform: capitalize;
}

.pub-secound .pub-secound-head p {
   /* text-align: center; */
   font: normal normal 600 20px/24px Montserrat;
   letter-spacing: 0px;
   color: #484848;
}

.box {
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 13px 36px #3C4F7733;
   border: 1px solid #F0F0F0;
   border-radius: 43px;
   display: flex;
   padding: 40px;
}

.box .bleft h6 {
   text-align: left;
   font: normal normal bold 25px/30px Montserrat;
   letter-spacing: 0px;
   color: #1C3F52;
   margin-top: 10px;
}

.box .bright ul li {
   display: flex;
   padding-bottom: 25px;
}

.box .bright ul li div:first-child {
   margin-right: 15px;
}

.box .bright ul li div:last-child b {
   text-align: left;
   font: normal normal bold 20px/24px Montserrat;
   letter-spacing: 0px;
   color: #484848;
}

.box .bright ul li div:last-child p {
   text-align: left;
   font: normal normal 500 16px/19px Montserrat;
   letter-spacing: 0px;
   color: #484848;
}

.magz {
   position: relative;
   display: flex;
   align-items: center;
   margin-bottom: 78px;
   font: normal normal 500 18px/22px Montserrat;
   letter-spacing: 0px;
   color: #7A878E;
}

.mag {
   width: 14px;
   height: 0px;
   border: 6px solid #FF9300;
   border-radius: 10px;
   margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 767px) {
   .pub-secound .pub-secound-head h2 {
      font: normal normal bold 22px/49px Montserrat
   }


   .box .bleft h6 {
      font: normal normal bold 17px/24px Montserrat
   }

   .solution .topsection1 .bannersection .topimage {
      margin-top: -150px;
   }

   body .soltwo .toptxt {
      width: 60%;
      font-size: 15px;
      margin-top: 130px;
      padding: 0 20px;
      box-sizing: border-box;
      max-width: 60%;
      margin-bottom: 30px;
   }

   .cashxbck {
      margin-top: -60px;
   }

   .sol-third .svgflip2 {
      margin-top: -18px !important;
   }

   .solbck {
      padding-bottom: 70px;
   }
}

@media (max-width: 1024px) {
   .box {
      flex-direction: column;
   }

   .pub-secound .pub-secound-head p {
      font: normal normal 600 20px/25px Montserrat;
      margin-left: -12%;

   }

   /* .pub-secound .pub-secound-head h2 {
      text-align: center;

   } */

}