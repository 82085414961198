.soltwo{
    width: 100%;
    margin-top: -150px;
}
.soltwo .topimage{
    width: 70%;;
}

.soltwo .toptxt{
    width: 20%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    bottom: 121px;
    font-size: 20px;
}

