.third {
    position: relative;
}

.massive-ywaves {
    /* position: relative;
     background: url("../../../../src/staticassets/homepage/third/background/yellowwaves2@2x.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover; */
    /* position: absolute;
    width: 100%;
    z-index: 0; */


}

.massivebck {
    background: rgb(255, 231, 130);
    background: linear-gradient(180deg, rgba(255, 231, 130, 1) 0%, rgba(255, 255, 255, 1) 80%);
    /* height: 416px; */
}

.massive-ywaves {
    position: relative;
    z-index: 1;
}

.svgflip1 svg {
    position: relative;
    margin-bottom: -5px;
}

/* .svgflip1 svg{
    position: absolute;
}
.svgflip1 svg:first-child{
    top: -24px;
}

.svgflip1 svg:nth-child(2){
    top: -12px;
} */

.massive-ywaves img {
    width: 100%;
}

.massive-scale h3 {
    text-align: center;
    font: normal normal 600 30px/37px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 57px;
}

.massive-scale {
    text-align: center;
    position: relative;
    z-index: 1;

}

.massive-scale .row .first,
.massive-scale .row .secound,
.massive-scale .row .third {
    flex: 33.33%;
}

.massive-scale .row .first p,
.massive-scale .row .secound p,
.massive-scale .row .third p {
    font: normal normal bold 73px/77px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    margin: 0px;
}

.massive-scale .row .first span,
.massive-scale .row .secound span,
.massive-scale .row .third span {
    text-align: center;
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
}

@media (min-width: 320px) and (max-width: 767px) {

    .massive-scale .row .first p,
    .massive-scale .row .secound p,
    .massive-scale .row .third p {
        font: normal normal bold 53px/50px Montserrat;
    }

    .massive-scale .row .first span,
    .massive-scale .row .secound span,
    .massive-scale .row .third span {
        font: normal normal 600 14px/18px Montserrat;
    }

    .massive-scale {
        padding: 15px;
    }

    .massive-scale h3 {
        font: normal normal 600 23px/37px Montserrat;
    }
}

@media (min-width: 320px) and (max-width: 767px) {

    .massive-scale .row .first p,
    .massive-scale .row .secound p,
    .massive-scale .row .third p {
        font: normal normal bold 43px/57px Montserrat;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .massive-scale {
        padding: 100px 10px;
    }

    .massive-scale .row .first p,
    .massive-scale .row .secound p,
    .massive-scale .row .third p {
        font: normal normal bold 53px/57px Montserrat;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .massive-scale {
        padding: 39px 0px 120px;
    }
}

@media (min-width: 1025px) {
    .massive-scale {
        padding: 39px 0px 150px;
    }
}