/* Larger than mobile */
@media (min-width: 320px) {}

@media (min-width: 320px) and (max-width: 767px) {
  .sleft img {
    width: 100%;
    height: 100%;
  }

  .home-fith {
    margin-top: 15%;
  }

}

/* Larger than tablet */
@media (min-width: 767px) {
  .home-secound {
    margin-top: 250px;
  }

  .home-third {
    margin-top: 100px;
  }



}

/* Larger than desktop */
@media (min-width: 1024px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}