.publisher .bannersection .topimage img {
    width: 100%;
    height: 100%;
    max-width: 710px;
    max-height: 474px;
}

.topsection {
    background: #305AB9;
    padding-top: 78px;

}

.publisher .bannersection .one p {
    font: normal normal 500 22px/27px Montserrat;
    color: #fff;
}