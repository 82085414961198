.pub-third h2 {
    text-align: left;
    font: normal normal 600 43px/53px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
    display: block;
    padding-left: 79px;
}

.pub-thi-back {
    background: rgb(141, 190, 226);
    background: linear-gradient(180deg, rgba(141, 190, 226, 1) 0%, rgba(255, 255, 255, 1) 80%);
    padding: 50px 0px;
    margin-top: -7px;
}

.pub-third {
    margin-top: 130px;
}

.pub-third .pub-third-head {
    padding-left: 84px;
}

@media (min-width: 1025px) {
    .pub-third .pub-third-head h2 {
        width: 62%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .pub-third h2 {
        font: normal normal 600 21px/40px Montserrat;
        padding-left: 0%;
    }

    .pub-third h2 b {
        font: normal normal 800 25px/30px Montserrat;
    }
}

.pub-third h2 b {
    text-align: left;
    font: normal normal 800 53px/63px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
}

.pub-third .box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 36px #3C4F7733;
    border: 1px solid #F0F0F0;
    border-radius: 43px;
    display: flex;
    padding: 20px;
}

.pub-third .box .bleft h6 {
    text-align: left;
    font: normal normal bold 25px/30px Montserrat;
    letter-spacing: 0px;
    color: #1C3F52;
    margin-top: 25px;
}

.pub-third .box .bleft h6 span {
    font: normal normal 400 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #7A878E;
    display: block;

}

.pub-third .box .bright ul li {
    display: block;
    padding-bottom: 25px;
    text-align: center;

}

.pub-third .box .bright ul li div:first-child {
    margin-right: 0px;
    background: #99AAC9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #8484842C;
    mix-blend-mode: multiply;
    border-radius: 21px;
    padding: 10px;
    color: #fff;

}

.pub-third .box .bright ul li div:first-child p {
    text-align: center;
    font: normal normal bold 29px/35px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.pub-third .box .bright ul li div:first-child span {
    color: #fff;
    font: normal normal medium 13px/16px Montserrat;
    letter-spacing: 0px;
}

.pub-third .box .bright ul li div:first-child b {
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #364157;
    text-transform: uppercase;
}

/* .pub-third  .box .bright ul li div:last-child b{
    text-align: left;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #484848;
}
.pub-third .box .bright ul li div:last-child p{
    text-align: left;
font: normal normal 500 16px/19px Montserrat;
letter-spacing: 0px;
color: #484848;
} */
@media (min-width: 1025px) {
    .pub-third .box .bright ul li div:first-child p.t {
        margin-top: 5px;
    }

}

@media (max-width: 1024px) {
    .pub-third .box .bright {
        order: 2;
    }

    .col-2 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .pub-third .box .bright ul {
        display: flex;
    }

    .pub-third .box .bright ul li {
        width: 50%;
        margin-right: 10px;
    }

    .pub-third .box .bright ul li:first-child p:last-child {
        padding-top: 10px;
    }

}