.topimg{
    top:0px;
}
.thankyou{
    min-height: 1000px;
}


.thankyou .topsection .bannersection .topimage{
    margin: 0 auto;
}
.thankyou .topsection .bannersection .one {
    display: flex;
    justify-content: left;
}
.thankyou .topsection .bannersection .one h1 {
    text-align: left;
    font: normal normal 600 43px/49px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 50%;
}