.pub-fourth .pub-fourth-head h2 {
    text-align: center;
    font: normal normal bold 48px/58px Montserrat;
    letter-spacing: 0px;
    color: #262626;
    text-transform: capitalize;
}

.pub-fourth {
    /* margin-top: 80px; */
}

.pub-fourth .pub-fourth-head {
    padding-bottom: 62px;
}

.pub-fourth .pub-fourth-head p {
    text-align: center;
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #484848;
    width: 50%;
    margin: 0 auto;
}

.circle .cfirst ul {
    position: relative;
    z-index: 1;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle .cfirst img {
    width: 100%;
    height: 100%;
}


.smallcircle .smc1 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #DDF5FD 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 36px #1E609A52;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.smallcircle .smc2 {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #3891AF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 36px #1E609A52;
    opacity: 0.3;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.csecound .lc1,
.csecound .lc2,
.csecound .lc3,
.csecound .lc4,
.csecound .lc5 {
    display: flex;
    padding-bottom: 40px;
    position: relative;
    width: 72%;
    text-align: left;
    align-items: center;
    position: relative;
}

@media (min-width: 320px) and (max-width: 767px) {
    .pub-fourth .pub-fourth-head h2 {
        font: normal normal bold 30px/20px Montserrat
    }

    .pub-fourth .pub-fourth-head p {
        font: normal normal 600 16px/24px Montserrat;
        width: 75%;
    }

    .csecound .lc1,
    .csecound .lc2,
    .csecound .lc3,
    .csecound .lc4,
    .csecound .lc5 {
        margin-left: 15%;
    }
}

@media (max-width: 1024px) {
    .circle {
        flex-direction: column;
    }

}

@media (min-width: 1025px) {
    .circle {
        margin-bottom: -138px;
    }

    .circle .cfirst {
        flex: 0 0 60%;
        text-align: center;
    }

    .circle .cscou {
        flex: 0 0 40%;
        text-align: center;
    }
}

@media (min-width: 1025px) {
    .bigcircle .cscou {
        float: left;
    }

    .csecound {
        margin-top: -186px;
    }

    .csecound ul {
        position: relative;
        margin-left: -20px;
    }

    .csecound .lc1,
    .csecound .lc2,
    .csecound .lc3,
    .csecound .lc4,
    .csecound .lc5 {
        display: flex;
        padding-bottom: 21px;
        position: relative;
        width: 68%;
        text-align: left;
        align-items: center;
        position: relative;
    }

    .csecound .lc1 {
        left: -26%;
    }

    .csecound .lc2 {
        left: -14%;
    }

    .csecound .lc3 {
        left: -5%;
    }

    .csecound .lc4 {
        left: -2%;
    }

    .csecound .lc5 {
        left: -3%;
    }
}

.csecound .lc1 p,
.csecound .lc2 p,
.csecound .lc3 p,
.csecound .lc4 p,
.csecound .lc5 p {
    padding-left: 10px;
    font: normal normal 500 17px/20px Montserrat;
    letter-spacing: 0px;
    color: #454545;
}


/* @media (min-width: 320px) and (max-width: 600px){


    .circle .cfirst ul li.fc{
        width: 320px;
   height: 320px;

    }
    .circle .cfirst ul li.sc{
        width: 256px;
   height: 256px;
    }
    .circle .cfirst ul li.tc{
        width: 230px;
   height: 230px;
    }
    .circle .cfirst ul li.foc{
        width: 198px;
   height: 198px;
    }



} */